import React, { memo, useEffect, useState, useCallback, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { QuestionCircleOutlined } from '@ant-design/icons'
import {
  useTranslation,
  Row,
  Title,
  PrimaryButton,
  DangerButton,
  Confirm,
  RoleControlled,
  DeleteButtonStyle,
  selectPages,
  handleFile
} from '@gk-devteam/apmc-core-web'

import {
  selectPropertyName,
  selectRefuseIssue,
  selectCurrentCalendar,
  selectRefuseLoading,
  selectRefuseHiddenBy,
  selectRefuseHiddenById,
  selectRefusePosting
} from '../../../../selectors'
import { FETCH_REFUSE_CALENDAR, RESET_REFUSE_CALENDAR, DELETE_CALENDAR, POST_CALENDAR } from '../../../../types/panels/refuseTypes'

import CurrentCalendar from './CurrentCalendar'
import CalendarModal from './CalendarModal'
import ReportModal from './ReportModal'
import PanelLayout from '../../../../components/Display/Panels/PanelLayout'
import { CalendarPickerStyle } from './PropertyRefusePage.style'

const PropertyRefusePage = ({ propertyID }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const loading = useSelector(selectRefuseLoading)
  const posting = useSelector(selectRefusePosting)
  const propertyName = useSelector(selectPropertyName)
  const currentCalendar = useSelector(selectCurrentCalendar)
  const hasIssue = useSelector(selectRefuseIssue)
  const [reportModalVisible, setReportModalVisible] = useState(false)
  const [calendarModalVisible, setCalendarModalVisible] = useState(false)
  const pages = useSelector(selectPages)
  const calendarInputRef = useRef()

  const breadcrumbs = [
    {
      label: 'navigation:properties',
      link: '/properties'
    },
    {
      text: propertyName || '',
      link: `/properties/edit/${propertyID}`
    },
    {
      label: 'navigation:properties_refuse',
      link: `/properties/edit/${propertyID}/refuse`
    }
  ]

  useEffect(() => {
    dispatch({ type: FETCH_REFUSE_CALENDAR, id: propertyID })
    return () => {
      dispatch({ type: RESET_REFUSE_CALENDAR })
    }
  }, [dispatch, propertyID])

  const _closeReportModal = useCallback(
    () => {
      setReportModalVisible(false)
    },
    []
  )
  const _closeCalendarModal = useCallback(
    () => {
      setCalendarModalVisible(false)
    },
    []
  )

  const _handleReportClick = () => {
    setReportModalVisible(true)
  }
  const _handleCalendarClick = () => {
    if (pages.custom.casa) {
      calendarInputRef.current && calendarInputRef.current.click()
      return
    }
    setCalendarModalVisible(true)
  }
  const _handleRemoveCalendar = () => {
    dispatch({ type: DELETE_CALENDAR, id: propertyID })
  }

  // Handle direct upload for casa companies
  const _handleInputClick = (e) => {
    e.target.value = null
  }
  const _handleFileInputChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      _handleAddCalendar(e.target.files[0])
    } else {
      console.debug('no file, not updating.')
    }
  }
  const _handleAddCalendar = async (fileToProcess) => {
    try {
      const file = await handleFile({
        file: fileToProcess,
        types: ['pdf'],
        maxSize: 20
      })
      const blobURL = URL.createObjectURL(file.file)

      dispatch({
        type: POST_CALENDAR,
        data: {
          pdf: blobURL,
          pdf_filename: fileToProcess.name,
          delete_pdf: false
        },
        id: propertyID
      })
    } catch (error) {}
  }

  return (
    <PanelLayout
      breadcrumbs={breadcrumbs}
      loading={loading || posting}
      hiddenSelector={selectRefuseHiddenBy}
      hiddenByIdSelector={selectRefuseHiddenById}
      propertyID={propertyID}
      noLegend
    >
      <ReportModal
        visible={reportModalVisible}
        onCancel={_closeReportModal}
        id={propertyID}
      />
      <CalendarModal
        visible={calendarModalVisible}
        onCancel={_closeCalendarModal}
        id={propertyID}
      />
      <Row justify="between" align="center" mt="R">
        <Title label="navigation:properties_refuse" />
        <div>
          {currentCalendar ? (
            <RoleControlled authorized="refuse.functions.delete">
              <Confirm
                title="delete_confirm"
                placement="topRight"
                onConfirm={_handleRemoveCalendar}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              >
                <DeleteButtonStyle reverse>
                  {t('refuse:remove_calendar')}
                </DeleteButtonStyle>
              </Confirm>
            </RoleControlled>
          ) : null}
          <RoleControlled authorized="!custom.casa">
            <RoleControlled authorized="refuse.functions.reportCalendar">
              <DangerButton
                label="refuse:report_error"
                handleClick={_handleReportClick}
                withMargin
                disabled={hasIssue}
                help={t(
                  hasIssue
                    ? 'refuse:report_disabled_tooltip'
                    : 'refuse:report_active_tooltip',
                  { companyName: 'GoldKey' }
                )}
              />
            </RoleControlled>
          </RoleControlled>
          <RoleControlled authorized="refuse.functions.add">
            <RoleControlled authorized="custom.casa">
              <label ref={calendarInputRef}>
                <CalendarPickerStyle
                  type="file"
                  name="pdf"
                  id="pdf"
                  onChange={_handleFileInputChange}
                  onClick={_handleInputClick}
                />
              </label>
            </RoleControlled>
            <PrimaryButton
              label="refuse:add_calendar"
              handleClick={_handleCalendarClick}
              reverse
              withMargin
            />
          </RoleControlled>
        </div>
      </Row>
      <CurrentCalendar />
    </PanelLayout>
  )
}
export default memo(PropertyRefusePage)

PropertyRefusePage.propTypes = {
  propertyID: PropTypes.string.isRequired
}
PropertyRefusePage.defaultProps = {}
