import React, { useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import { CancelToken } from 'axios'
import { saveAs } from 'file-saver'
import {
  RESET_PARAMS,
  useTranslation,
  selectQueryParams,
  dynamicLink,
  PageLayout,
  Row,
  Title,
  Table,
  Tag,
  PrimaryButton,
  RoleControlled,
  LightText,
  selectPages,
  selectUserRole
} from '@gk-devteam/apmc-core-web'
import { message } from 'antd'

import {
  FETCH_CONTRACTS,
  RESET_CONTRACTS,
  DELETE_CONTRACT,
  POST_ACCEPT_RENEW_CONTRACT
} from '../../../types'
import {
  selectContractsLoading,
  selectContractsData,
  selectContractsCount
} from '../../../selectors'
import { contractsSearchSchema } from '../../../validation'
import { downloadChecklistPDF, fetchContracts } from '../../../services'
import { CONTRACT_STATUS, IMPORT_TYPES, ROLES } from '../../../constants'

import ContractsSearchBar from './ContractsSearchBar'
import DownloadCSVButton from '../../../components/Misc/DownloadCSVButton'
import { ResidentCellStyle, TagStyle } from './ContractsPage.style'

const breadcrumbs = [
  {
    label: 'navigation:contracts',
    link: '/contracts'
  }
]

const ContractsPage = ({ navigate, location }) => {
  const dispatch = useDispatch()
  const query = useSelector(selectQueryParams)
  const { t } = useTranslation(['common', 'contracts'])
  const prevQuery = useRef(null)
  const pages = useSelector(selectPages)
  const userRole = useSelector(selectUserRole)

  const fetchData = useCallback(
    () => {
      const schema = contractsSearchSchema()
      schema.isValid(query)
        .then(valid => {
          if (valid) {
            dispatch({ type: FETCH_CONTRACTS, query })
          } else {
            console.warning('params invalid, query using default params')
            dispatch({ type: FETCH_CONTRACTS })
          }
        })
    },
    [dispatch, query]
  )

  useEffect(() => {
    if (prevQuery.current == null) {
      prevQuery.current = query
      fetchData()
    } else {
      if (!isEqual(prevQuery.current, query)) {
        prevQuery.current = query
        fetchData()
      }
    }
  }, [query, fetchData])

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_CONTRACTS })
      dispatch({ type: RESET_PARAMS })
    }
  }, [dispatch, location.pathname])

  const statusCell = useCallback(
    (value, cellKey, rowID, className, data) => {
      let status, color
      for (const key in CONTRACT_STATUS) {
        const element = CONTRACT_STATUS[key]
        if (value === element.value) {
          status = `contracts:status.${key}`
          color = element.color
        }
      }

      return (
        <>
          <Tag label={status} color={color} className={className} />
          { data?.casa && <Tag label='casa' color='success' /> }
          <RoleControlled authorized={pages.custom.anabuki && data?.premium}>
            <Row mt="XS">
              <Tag label='contracts:anabuki.premium.short' color='process' className={className} />
            </Row>
          </RoleControlled>
        </>
      )
    },
    [pages]
  )
  const residentCell = useCallback(
    (value, cellKey, rowID, className, data) => {
      let label, color
      switch (data && data.resident_login_status) {
        case 1:
          label = 'app_users:login_status.no_login'
          color = 'text'
          break
        case 2:
          label = 'app_users:login_status.active'
          color = 'info'
          break
        case 3:
          label = 'app_users:login_status.inactive'
          color = 'pageTitle'
          break

        default:
          break
      }
      return (
        <ResidentCellStyle align="start">
          <LightText text={value} />
          <TagStyle label={label} color={color}/>
        </ResidentCellStyle>
      )
    },
    []
  )

  const _handleResidingRenew = useCallback(
    (data) => {
      if (data?.status && data?.services?.cloudsign) {
        switch (data.status) {
          case CONTRACT_STATUS.RESIDING.value:
            return true
          default:
            return false
        }
      }
      return false
    },
    []
  )
  const _handleRenewingSettings = useCallback(
    (data) => {
      if (data?.status && data?.services?.cloudsign) {
        switch (data.status) {
          case CONTRACT_STATUS.IN_RENEWING_PERIOD.value:
            return true
          default:
            return false
        }
      }
      return false
    },
    []
  )
  const _handleRenewingConfirm = useCallback(
    (data) => {
      if (data?.status && data?.services?.cloudsign) {
        switch (data.status) {
          case CONTRACT_STATUS.ASKING_FOR_RENEWAL_UNREAD.value:
          case CONTRACT_STATUS.ASKING_FOR_RENEWAL_READ.value:
          case CONTRACT_STATUS.RENEWING.value:
          case CONTRACT_STATUS.RENEWING_CONFIRMED.value:
          case CONTRACT_STATUS.WAITING_RENEWAL_CONFIRMATION.value:
            return true
          default:
            return false
        }
      }
      return false
    },
    []
  )
  const _handleAcceptRenewal = useCallback(
    (data) => {
      if (data?.status && data?.services?.cloudsign) {
        switch (data.status) {
          case CONTRACT_STATUS.WAITING_RENEWAL_CONFIRMATION.value:
            return true
          default:
            return false
        }
      }
      return false
    },
    []
  )
  const _handleCancelConfirm = useCallback(
    (data) => {
      if (data?.status && pages?.contracts.functions.cancel) {
        switch (data.status) {
          case CONTRACT_STATUS.REQUESTING_CANCEL.value:
          case CONTRACT_STATUS.CANCELING.value:
          case CONTRACT_STATUS.MOVING_OUT.value:
          case CONTRACT_STATUS.MOVED_OUT.value:
            return true
          default:
            return false
        }
      }
      return false
    },
    [pages]
  )

  const _handleDownloadClick = async (id, close, row) => {
    const hideMessage = message.loading(t('file_download'), 0)
    const filename = `【${row.property_name}】${row.room_number}.pdf`
    close()
    try {
      const cancelSource = CancelToken.source()
      const res = await downloadChecklistPDF(id, cancelSource)
      if (hideMessage) hideMessage()
      saveAs(res.data, filename)
    } catch (error) {
      if (hideMessage) hideMessage()
      console.warn(error)
    }
  }

  let contractsColumns = [
    {
      title: t('contracts:contractor_name'),
      dataIndex: 'contractor_name',
      key: 'contractor_name',
      cellTitle: true
      // sortable: true
    },
    {
      title: pages?.custom?.house_maker ? t('contracts:contract_manage_id') : t('properties:property_name'),
      dataIndex: pages?.custom?.house_maker ? 'contract_manage_id' : 'property_name',
      key: pages?.custom?.house_maker ? 'contract_manage_id' : 'property_name'
      // sortable: true
    },
    {
      title: t('properties:room_number'),
      dataIndex: 'room_number',
      key: 'room_number',
      sortable: true,
      authorized: '!custom.house_maker'
    },
    {
      title: t('contracts:resident_name'),
      dataIndex: 'resident_name',
      key: 'resident_name',
      render: residentCell
    },
    {
      title: t('contracts:contract_start_date'),
      dataIndex: 'contract_start_date',
      key: 'contract_start_date'
    },
    {
      title: t('contracts:contract_end_date'),
      dataIndex: 'contract_end_date',
      key: 'contract_end_date',
      authorized: '!custom.house_maker'
    },
    {
      title: t('contracts:move_out_date'),
      dataIndex: 'move_out_date',
      key: 'move_out_date',
      authorized: '!custom.house_maker'
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      render: statusCell
    },
    // {
    //   title: '最終更新日時',
    //   dateTime: true,
    //   dataIndex: 'modified',
    //   key: 'modified',
    //   sortable: true
    // },
    {
      title: ' ',
      dataIndex: '',
      key: 'option_menu',
      supportedRoles: [
        ROLES.AGENCY_MAIN_ADMIN,
        ROLES.AGENCY_ADMIN,
        ROLES.AGENCY_EDITOR,
        ROLES.AGENCY_MEMBER,
        ROLES.OWNER
      ],
      rowMenu: [
        {
          label: t('contracts:status_panel.renewal.ask_renewal'),
          onClick: (id, close) => {
            _handleNavigateToRenew(id, close)
          },
          shouldRender: _handleResidingRenew
        },
        {
          label: t('contracts:status_panel.IN_RENEWING_PERIOD.renewal_settings'),
          onClick: (id, close) => {
            _handleNavigateToRenew(id, close)
          },
          shouldRender: _handleRenewingSettings
        },
        {
          label: t('contracts:status_panel.ASKING_FOR_RENEWAL.confirm_renewal'),
          onClick: (id, close) => {
            _handleNavigateToRenew(id, close)
          },
          shouldRender: _handleRenewingConfirm
        },
        {
          label: t('contracts:status_panel.WAITING_RENEWAL_CONFIRMATION.accept_renewal'),
          onClick: (id, close) => {
            dispatch({ type: POST_ACCEPT_RENEW_CONTRACT, id })
            close()
          },
          shouldRender: _handleAcceptRenewal
        },
        {
          label: t('navigation:contracts_cancel'),
          onClick: (id, close) => {
            _handleNavigateToCancel(id, close)
          },
          shouldRender: _handleCancelConfirm
        },
        {
          label: t('copy'),
          onClick: (id, close) => {
            _handleCopy(id, close)
          },
          shouldRender: 'contracts.functions.add'
        },
        {
          label: t('admin_tasks:checklist_download'),
          onClick: _handleDownloadClick,
          shouldRender: 'custom.anabuki'
        },
        {
          label: t('admin_tasks:checklist_download'),
          onClick: _handleDownloadClick,
          shouldRender: 'custom.casa'
        },
        {
          label: t('admin_tasks:to_contractor_chat'),
          onClick: (id, close, row) => {
            navigate(`/admin_tasks/chat/contract:${id}_${row.contractor_id}`)
            close()
          },
          shouldRender: 'admin_tasks.functions.chat'
        },
        {
          label: t('admin_tasks:to_resident_chat'),
          onClick: (id, close, row) => {
            navigate(`/admin_tasks/chat/contract:${id}_${row.resident_id}`)
            close()
          },
          shouldRender: 'admin_tasks.functions.chat'
        },
        {
          label: t('admin_tasks:to_checklist'),
          onClick: (id, close) => {
            navigate(`/admin_tasks/checklist?contract_id=${id}`)
            close()
          },
          shouldRender: 'admin_tasks.functions.checklist'
        },
        {
          label: t('delete'),
          danger: true,
          onClick: (id, close) => {
            console.log('warning', id)
            dispatch({ type: DELETE_CONTRACT, id })
            close()
          },
          shouldRender: 'contracts.functions.delete'
        }
      ]
    }
  ]

  if (pages.custom.yasue) {
    contractsColumns = [
      {
        title: t('yasue:contracts.contract_manage_id'),
        dataIndex: 'contract_manage_id',
        key: 'contract_manage_id',
        cellTitle: true
      },
      {
        title: t('yasue:contracts.contractor_name'),
        dataIndex: 'contractor_name',
        key: 'contractor_name'
      },
      {
        title: ' ',
        dataIndex: '',
        key: 'option_menu',
        supportedRoles: [
          ROLES.AGENCY_MAIN_ADMIN,
          ROLES.AGENCY_ADMIN,
          ROLES.AGENCY_EDITOR,
          ROLES.AGENCY_MEMBER,
          ROLES.OWNER
        ],
        rowMenu: [
          {
            label: t('copy'),
            onClick: (id, close) => {
              _handleCopy(id, close)
            },
            shouldRender: 'contracts.functions.add'
          },
          {
            label: t('yasue:contracts.add_construction_work'),
            onClick: (id, close, row) => {
              navigate('/yasue/construction_works/add', {
                state: { contract: row }
              })
              close()
            },
            shouldRender: 'admin_tasks.functions.chat'
          },
          {
            label: t('admin_tasks:to_contractor_chat'),
            onClick: (id, close, row) => {
              navigate(`/admin_tasks/chat/contract:${id}_${row.contractor_id}`)
              close()
            },
            shouldRender: 'admin_tasks.functions.chat'
          },
          {
            label: t('delete'),
            danger: true,
            onClick: (id, close) => {
              console.log('warning', id)
              dispatch({ type: DELETE_CONTRACT, id })
              close()
            },
            shouldRender: 'contracts.functions.delete'
          }
        ]
      }
    ]
  }

  const _handleNavigateToRenew = useCallback(
    (id, close) => {
      close()
      navigate(`/contracts/edit/${id}/renew`)
    },
    [navigate]
  )
  const _handleNavigateToCancel = useCallback(
    (id, close) => {
      close()
      navigate(`/contracts/edit/${id}/cancel`)
    },
    [navigate]
  )
  const _handleCopy = useCallback(
    (id, close) => {
      close()
      navigate('/contracts/add', { state: { id, copy: true } })
    },
    [navigate]
  )

  const navigateToAdd = () => {
    navigate('./add/')
  }
  const navigateToCSV = () => {
    navigate(`/import?type=${IMPORT_TYPES.CONTRACTS}`)
  }
  const navigateToDetails = (id, row, e) => {
    const url = `/contracts/edit/${id}`
    dynamicLink({
      url,
      event: e,
      navigate
    })
  }

  return (
    <PageLayout
      sidebar={
        () => <ContractsSearchBar />
      }
      breadcrumbs={breadcrumbs}
    >
      <Row justify="between" align="center" mt="R" wrap={'wrap'}>
        <Title label="navigation:contracts_list"/>
        <div>
          <RoleControlled authorized={pages?.custom?.yasue
            ? userRole === ROLES.AGENCY_MAIN_ADMIN
            : true
          }>
            <DownloadCSVButton
              schemaBuilder={contractsSearchSchema}
              fetchCSV={fetchContracts}
              filename="contracts.csv"
            />
          </RoleControlled>
          <RoleControlled authorized="imports.functions.contracts">
            <PrimaryButton
              label="add_csv"
              handleClick={navigateToCSV}
              withMargin
            />
          </RoleControlled>
          <RoleControlled authorized="contracts.functions.add">
            <PrimaryButton
              label="contracts:add_contract"
              handleClick={navigateToAdd}
              reverse
              withMargin
            />
          </RoleControlled>
        </div>
      </Row>
      <Table
        fullHeight
        columns={contractsColumns}
        rowKey="id"
        loadingSelector={selectContractsLoading}
        resultsCountSelector={selectContractsCount}
        dataSelector={selectContractsData}
        onRowClick={navigateToDetails}
        leftStickyColumnCount={pages?.custom?.yasue ? 0 : 1}
        rightStickyColumnCount={1}
      />
    </PageLayout>
  )
}
export default ContractsPage

ContractsPage.propTypes = {
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired
}
ContractsPage.defaultProps = {

}
