import {
  styled,
  SPACING
} from '@gk-devteam/apmc-core-web'
import { Empty } from 'antd'

export const EmptyStyle = styled(Empty)`
  &&& {
    margin-top: ${SPACING.LARGEST}rem;
  }
`
export const CalendarContainerStyle = styled.div`
  margin-top: ${SPACING.LARGEST}rem;
  .react-pdf__Page > canvas {
    margin: auto;
  }
`

export const LinkContainerStyle = styled.div`
  margin-bottom: ${SPACING.REGULAR}rem;
  &&& > a {
    display: inline-flex;
    align-items: center;

    span {
      margin-left: ${SPACING.SMALL}rem;
    }
  }
`

export const ImageContainerStyle = styled.div`
  width: 80%;
  margin: auto;

`

export const CalendarPickerStyle = styled.input`
  display: none;
`
