/* eslint-disable no-template-curly-in-string */
import customizedYup from './_yup'
import { i18nValidation as i18n } from '../../locales'

export const chatSettingsSchema = (locale = 'ja') => {
  i18n.changeLanguage(locale)
  const yup = customizedYup()
  return yup.object().shape({
    autoMessage: yup
      .bool()
      .required()
      .label(i18n.t('common:chat.auto_reply')),
    message: yup
      .string()
      .nullable()
      .when('autoMessage', {
        is: true,
        then: yup.string().required(),
        otherwise: yup.string().nullable()
      })
      .label(i18n.t('common:chat.auto_reply_message')),
    startTime: yup
      .string()
      .nullable()
      .isTime()
      .label(i18n.t('common:chat.auto_reply_start_time')),
    endTime: yup
      .string()
      .nullable()
      .isTime()
      .label(i18n.t('common:chat.auto_reply_end_time')),
    emailNotifications: yup
      .bool()
      .required()
      .label(i18n.t('settings:chat.notifications_settings')),
    email: yup
      .string()
      .email()
      .nullable()
      .label(i18n.t('settings:chat.email'))
  })
}
