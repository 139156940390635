import React, { memo } from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  Row,
  Form,
  Checkbox,
  TimeRangePicker,
  TextArea,
  TextInput
} from '@gk-devteam/apmc-core-web'

import { chatSettingsSchema } from '../../validation'
import { LeftContainerStyle } from './ChatSettingsForm.style.js'
import Weekdays from './Weekdays'
import TimePreview from './TimePreview'

const initialData = {
  autoMessage: false,
  message: '',
  daysOfWeek: {
    Mon: false,
    Tue: false,
    Wed: false,
    Thu: false,
    Fri: false,
    Sat: false,
    Sun: false
  },
  startTime: '',
  endTime: '',
  emailNotifications: false,
  email: ''
}

const ChatSettingsForm = ({ data, handleSubmit, noCheckbox }) => {
  return (
    <Form
      id="chat_settings_form"
      handleSubmit={handleSubmit}
      validationSchema={chatSettingsSchema}
      // loadingSelector={selectLoadingAppUser}
      // postingSelector={selectPostingAppUser}
      initialData={{
        ...initialData,
        ...data
      }}
      // debug
    >
      <Card title="chat.auto_reply" mt="R">
        <Row align="start" wrap="wrap">
          <LeftContainerStyle>
            {
              noCheckbox
                ? null
                : (
                  <Row mb="S">
                    <Checkbox
                      mb="0"
                      autoH
                      name="autoMessage"
                      inputLabel="chat.auto_reply"
                      valueLabel="use"
                      help="chat.auto_reply_help"
                    />
                  </Row>

                )
            }
            <Weekdays />
            <Row>
              <TimeRangePicker
                size="L"
                name_start="startTime"
                name_end="endTime"
                label="chat.auto_reply_hours"
                placeholder={['chat.auto_reply_start_time_short', 'chat.auto_reply_end_time_short']}
                help="chat.auto_reply_hours_help"
              />
            </Row>
            <Row>
              <TextArea
                flex={1}
                name="message"
                label='chat.auto_reply_message'
                placeholder="chat.auto_reply_message_placeholder"
              />
            </Row>
          </LeftContainerStyle>
          <TimePreview />
        </Row>
      </Card>
      <Card title="settings:chat.notifications_settings" mt="L">
        <Row mb="R">
          <Checkbox
            mb="0"
            autoH
            name="emailNotifications"
            inputLabel="settings:chat.notifications"
            valueLabel="use"
            help="settings:chat.notifications_help"
          />
        </Row>
        <TextInput
          flex={1}
          name="email"
          label='settings:chat.email'
          placeholder="placeholders.email"
        />
      </Card>
    </Form>
  )
}

export default memo(ChatSettingsForm)

ChatSettingsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  data: PropTypes.object,
  noCheckbox: PropTypes.bool
}
