import React, { memo, useCallback, useRef, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { useNavigate } from '@reach/router'
import {
  useTranslation,
  getTimeElapsed,
  dynamicLink,
  LEFT_INFO,
  SPACING,
  TablePagination,
  Tag,
  Text,
  Row,
  selectPages,
  selectUserRole,
  RoleControlled
} from '@gk-devteam/apmc-core-web'

import {
  selectChecklistLoading,
  selectChecklistPosting,
  selectChecklistsCount,
  selectChecklistsData
  // selectChecklistCategories
} from '../../../selectors'
import { ROLES, SUPPORT_STATUS } from '../../../constants'

import { TableStyle } from '../adminTasks.style'
import { AllStatusRadioStyle } from './AdminTasksChecklistPage.style'
import { UPDATE_CHECKLIST_ISSUES_STATUS } from '../../../types'

const ChecklistsList = () => {
  const rowRef = useRef(null)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [tableMaxWidth, setTableMaxWidth] = useState(0)
  const pages = useSelector(selectPages)
  const [columns, setColumns] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const role = useSelector(selectUserRole)
  const posting = useSelector(selectChecklistPosting)
  // const categories = useSelector(selectChecklistCategories)

  useEffect(() => {
    handleTableWidth()
    window.addEventListener('resize', handleTableWidth, false)
    return () => {
      window.removeEventListener('resize', handleTableWidth, false)
    }
  }, [])
  const handleTableWidth = () => {
    const row = rowRef.current
    if (row) {
      const pageWidth = document.documentElement.clientWidth
      const maxWidth = pageWidth - LEFT_INFO - (SPACING.LARGE * 14) - (3.5 * 2 * 14)
      setTableMaxWidth(maxWidth)
    }
  }

  const _onRowSelected = useCallback(
    (id) => {
      // Handle rows selection
      if (typeof id === 'object' && Array.isArray(id)) {
        setSelectedRows([...id])
      }

      // Handle rows deselection
      if (id === null) {
        setSelectedRows([])
      }

      // Handle row selection
      if (typeof id === 'number') {
        const rowSet = new Set(selectedRows)
        if (rowSet.has(id)) {
          rowSet.delete(id)
        } else {
          rowSet.add(id)
        }
        setSelectedRows([...rowSet])
      }
    },
    [selectedRows]
  )

  const statusCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      let label, color
      switch (data.status) {
        case SUPPORT_STATUS.ARCHIVE:
          label = 'admin_tasks:status.archive'
          color = 'accent'
          break
        case SUPPORT_STATUS.UNUSED:
          label = 'admin_tasks:status.is_unused'
          color = 'text'
          break
        case SUPPORT_STATUS.COMPLETED:
          label = 'admin_tasks:status.is_completed'
          color = 'success'
          break
        case SUPPORT_STATUS.CONFIRMED:
          label = 'admin_tasks:status.is_confirmed'
          color = 'info'
          break
        case SUPPORT_STATUS.WAITING:
          label = 'admin_tasks:status.is_waiting'
          color = 'danger'
          break
        default:
          break
      }

      return <Tag label={label} color={color} />
    },
    []
  )
  const repairCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      if (value) return <Tag label="admin_tasks:checklist_repair.repair_needed" color="danger" />
      return null
    },
    []
  )
  const elapsedCell = useCallback(
    (value, cellKey, rowKey, className, data) => {
      const { last_user_message_time, last_message_time } = data
      return <Text text={getTimeElapsed(dayjs(last_user_message_time || last_message_time))} />
    },
    []
  )

  useEffect(() => {
    const columns = [
      {
        title: t('status'),
        dataIndex: 'status',
        key: 'status',
        render: statusCell,
        sortable: true
      },
      {
        title: t('title'),
        dataIndex: 'label',
        key: 'label',
        cellTitle: true
      },
      {
        title: t('genre'),
        dataIndex: 'checklist_category_name',
        key: 'checklist_category_name',
        sortable: true
      },
      {
        title: t('contracts:resident_name'),
        dataIndex: 'app_user_name',
        key: 'app_user_name'
      },
      {
        title: t('properties:property_name'),
        dataIndex: 'property_name',
        key: 'property_name'
      },
      {
        title: t('properties:room_number'),
        dataIndex: 'room_number',
        key: 'room_number'
      },
      {
        title: t('contracts:contract_id'),
        dataIndex: 'contract_id',
        key: 'contract_id'
      },
      {
        title: t('elapsed'),
        dataIndex: 'last_message_sent_at',
        key: 'last_message_sent_at',
        render: elapsedCell,
        sortable: true
      }
    ]

    if (pages?.custom?.anabuki) {
      columns.splice(1, 0, {
        title: t('admin_tasks:checklist_repair.flag'),
        dataIndex: 'repair',
        key: 'repair',
        render: repairCell,
        sortable: true
      })
    }

    setColumns(columns)
  }, [
    elapsedCell,
    repairCell,
    statusCell,
    selectedRows,
    t,
    pages
  ])

  const _handleWaitingChecklistRow = useCallback(
    (data) => {
      return data && data.status && data.status === SUPPORT_STATUS.WAITING
    },
    []
  )
  const _handleSelectableRow = useCallback(
    (data) => {
      if (pages?.custom?.anabuki) return !data?.repair
      return true
    },
    [pages.custom.anabuki]
  )

  const _handleStatusChange = (status) => {
    console.log(status, selectedRows)
    if (selectedRows.length > 0) {
      const onSuccess = () => {
        setSelectedRows([])
      }
      dispatch({
        type: UPDATE_CHECKLIST_ISSUES_STATUS,
        data: {
          ids: selectedRows,
          status
        },
        onSuccess
      })
    }
  }

  // const _handleInactiveChecklistRow = useCallback(
  //   (data) => {
  //     return data && !data.enabled
  //   },
  //   []
  // )

  const navigateToRoom = (id, row, e) => {
    const url = `/admin_tasks/checklist/${id}`
    dynamicLink({
      url,
      event: e,
      navigate
    })
  }

  const _renderStatusToggle = () => {
    return (
      <RoleControlled authorized={(pages?.custom?.anabuki || pages.custom.casa) && role <= ROLES.AGENCY_MEMBER}>
        <AllStatusRadioStyle
          name="checklist_status"
          handleChange={_handleStatusChange}
          options={[
            {
              label: 'admin_tasks:status.is_waiting',
              value: 4
            },
            {
              label: 'admin_tasks:status.is_confirmed',
              value: 3
            },
            {
              label: 'admin_tasks:status.is_completed',
              value: 2
            }
          ]}
          disabled={posting}
        />
      </RoleControlled>
    )
  }

  return (
    <div ref={rowRef}>
      { _renderStatusToggle() }
      <TableStyle
        fullHeight
        tableMaxWidth={tableMaxWidth}
        columns={columns}
        rowKey="id"
        loadingSelector={selectChecklistLoading}
        dataSelector={selectChecklistsData}
        onRowClick={navigateToRoom}
        onRowSelect={(pages?.custom?.anabuki || pages?.custom?.casa) && role <= ROLES.AGENCY_MEMBER ? _onRowSelected : undefined}
        selectedRows={selectedRows}
        dangerRow={_handleWaitingChecklistRow}
        selectableRow={_handleSelectableRow}
        // inactiveRow={_handleInactiveChecklistRow}
        noHeader
        noFooter
      />
      <Row justify="center" mt="R">
        <TablePagination
          resultsCountSelector={selectChecklistsCount}
        />
      </Row>
    </div>
  )
}

export default memo(ChecklistsList)

ChecklistsList.propTypes = {}
